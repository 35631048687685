import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Section from '../components/Section';
import Box from '../components/Styles/Box';
import Grid from '../components/Styles/Grid';
import Card from '../components/Styles/Card';
import Heading from '../components/Styles/Heading';
import Text from '../components/Styles/Text';
import PageHeader from '../components/PageHeader';
import MediaCard from '../components/MediaCard';
import Typography from '../components/Styles/Typography';
import Avatar from '../components/Avatar';

function blog({ data }) {
  const posts = data.allMdx.edges;

  return (
    <Layout maxWidth="1024px" margin="0 auto">
      <SEO title="Development Blog" />
      <PageHeaderStyled heading="Musings by ALTdev" arrow={false} />
      <Section>
        <Grid gridTemplateColumns={['1fr', 'repeat(1, 1fr)']} gridGap={[4, 4]}>
          {!posts && <p>Whoops, there aren't any posts to read right now.</p>}
          {posts &&
            posts.map((post) => (
              <ArticleStyles as="article" className="post">
                <Grid gridTemplateColumns={['1fr', '2fr 1fr']} gridGap={4}>
                  <Box>
                    <Link to={post.node.frontmatter.slug}>
                      <Typography as="h2">
                        {post.node.frontmatter.title}
                      </Typography>
                    </Link>
                    <div className="post__meta">
                      <Typography as="span" className="post__author">
                        <Avatar
                          initals="PK"
                          image={
                            post.node.frontmatter.authorAvatar?.childImageSharp
                              .gatsbyImageData
                          }
                        />
                        {post.node.frontmatter.author &&
                          post.node.frontmatter.author}
                      </Typography>
                      <Typography as="span">
                        <b>{post.node.timeToRead} min read</b>
                      </Typography>
                    </div>
                    <Typography>{post.node.excerpt}</Typography>
                    <Link to={post.node.frontmatter.slug}>
                      <Typography>Read More</Typography>
                    </Link>
                  </Box>
                  <GatsbyImage
                    image={
                      post.node.frontmatter.image?.childImageSharp
                        .gatsbyImageData
                    }
                  />
                </Grid>
              </ArticleStyles>
              // <MediaCard
              //   image={
              //     post.node.frontmatter.image?.childImageSharp.gatsbyImageData
              //   }
              //   heading={post.node.frontmatter.title}
              //   content={post.node.excerpt}
              //   linkSlug={post.node.frontmatter.slug}
              //   linkText="Read More"
              //   boxShadow={true}
              // />
            ))}
        </Grid>
      </Section>
    </Layout>
  );
}

const PageHeaderStyled = styled(PageHeader)`
  min-height: unset;
  padding: var(--sectionPadding);
`;

const ArticleStyles = styled(Card)`
  .post__meta {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
  .post__author {
    display: flex;
    align-content: center;
    align-items: center;
    gap: 0.5em;
  }
`;

export const query = graphql`
  query {
    allMdx {
      edges {
        node {
          id
          timeToRead
          frontmatter {
            slug
            author
            authorAvatar {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
            date
            description
            title
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
          excerpt
          timeToRead
        }
      }
    }
  }
`;

export default blog;
