import React, { Component } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import Card from './Styles/Card';
import Heading from './Styles/Heading';
import Text from './Styles/Text';
import LinkIcon from './LinkIcon';

const MediaCard = (props) => {
  const { image, heading, content, linkSlug, linkText, boxShadow } = props;
  return (
    <CardStyled link={linkSlug} boxShadow={boxShadow}>
      {image && <GatsbyImage image={image} />}
      <InnerContent>
        <HeadingStyled as="h3">{heading}</HeadingStyled>
        <Text dangerouslySetInnerHTML={{ __html: `${content}` }} />
        <LinkIcon>{linkText}</LinkIcon>
        <Link href={linkSlug} />
      </InnerContent>
    </CardStyled>
  );
};

const CardStyled = styled(Card)`
  padding: 0;
  :hover ${LinkIcon} {
    color: var(--secondary);
  }
`;

const InnerContent = styled.div`
  padding: 2em;
`;

const HeadingStyled = styled(Heading)`
  font-size: 1.4em;
`;

const Link = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;
const LinkText = styled.a`
  text-decoration: underline;
  color: var(--secondary);
`;

const IconWrap = styled.span`
  display: inline-block;
  width: 45px;
  height: 45px;
  margin-bottom: 1em;
`;

export default MediaCard;
